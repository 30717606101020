@use '../common/_variables' as v;

//input
.k-input{
    position: relative;
    .input-wrap{
        display: flex;
        background: v.$color-input;
        border-radius: 6px;
        width: 100%;
    }
    input{
        color: v.$color-text;
        flex: 1;
        padding: 0 16px;
        height: 40px;
        &::placeholder {
            color: v.$color-text-lighter;
        }
    }
    .prompt-text{
        padding: 5px 0;
        color: v.$color-text-lighter;
        font-size: 12px;
        line-height: 1.4;
        &.is-error{
            color: v.$color-error;
        }
    }
}
