@use '../common/_variables' as v;
.page-casino{
    @media (max-width:v.$desktop-size){
        padding-top: 10px;
    }
    @media (max-width: v.$tablet-size){
        padding-top: 20px;
    }
    >.k-btn-toggle-group{
        margin-bottom: 20px;
        display: none;
        @media (max-width:v.$desktop-size){
            display: flex;
        }
        @media (max-width: v.$tablet-size){
            margin-bottom: 0;
        }
    }
    // .casino-top{
    //     position: relative;
    //     background: linear-gradient(to bottom, #fff 20%,#f6f6f6);
    //     &::after{
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         z-index: 2;
    //         width: 100%;
    //         height: 100%;
    //         background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #f6f6f6);
    //     }
    //     .kv-cp {
    //         width: 95%;
    //         max-width: 1280px;
    //         position: relative;
    //         margin:  -2vw auto;
    //         z-index: 3;
            
    //         img {
    //             display: block;
    //             width: 100%;
    //         }
    //         @media (max-width: v.$mobile-size-l){
    //             width: 100%;
    //             margin: 0 auto;
    //             overflow: hidden;
    //             img {
    //                 width: 150%;
    //                 margin-left: -25%;
    //             }
    //         }
    //     }
    //     .main-wrap{
    //         position: relative;
    //         z-index: 3;
    //         padding: 40px 0;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         justify-content: center;
    //         min-height: calc(100vh - 60px);
    //         width: 600px;
    //         @media (max-width: v.$desktop-size){
    //             width: 70%;
    //         }
    //         @media (max-width:v.$tablet-size){
    //             align-items: flex-start;
    //             margin: 0 0 0 7%;
    //             width: 50%;
    //             min-height: unset;
    //         }
    //         @media (max-width: v.$mobile-size-l){
    //             width: 65%;
    //         }
    //     }
    //     .small-text-tag{
    //         margin-bottom: 20px;
    //         padding: 10px 20px;
    //         border-radius: 50px;
    //         background: #f5f6ff;
    //         font-size: 15px;
    //         font-weight: 500;
    //         transition: font-size .3s;
    //         @media (max-width: v.$tablet-size){
    //             font-size: 12px;
    //         }
    //     }
    //     h1{
    //         margin-bottom: 30px;
    //         font-size: 64px;
    //         font-weight: bold;
    //         line-height: 1.2;
    //         text-align: center;
    //         max-width: 100%;
    //         transition: font-size .3s;
    //         text-transform: capitalize;
    //         strong{
    //             display: block;
    //         }
    //         @media (max-width: v.$tablet-size){
    //             font-size: 32px;
    //             max-width: unset;
    //             text-align: left;
    //             line-height: 1.2;
    //             strong{
    //                 font-size: 44px;
    //                 margin-bottom: 8px;
    //             }
    //         } 
    //         @media (max-width: v.$mobile-size-l){
    //             font-size: 26px;
    //             margin-bottom: 10px;
    //             strong{
    //                 font-size: 34px;
    //             }
    //         }
    //     }
    //     p{
    //         margin-bottom: 30px;
    //         line-height: 1.5;
    //         color: v.$color-text-light;
    //         font-weight: 400;
    //         text-align: center;
    //         max-width: 600px;
    //         @media (max-width: v.$tablet-size){
    //             text-align: left;
    //             display: none;
    //         }
    //         @media (max-width: v.$mobile-size-l){
    //             font-size: 14px;
                
    //         }
    //     }
    //     p.desc-mobile{
    //         display: none;
    //         @media (max-width: v.$tablet-size){
    //             display: block;
    //         }
    //     }
    //     .button-group{
    //         display: flex;
    //         align-items: center;
    //         gap: 20px;
    //         >*{
    //             min-width: 130px;
    //         }
    //         @media (max-width: v.$tablet-size){
    //             width: 50%;
    //             >*{
    //                 flex: 1;
    //                 min-width: unset;
    //             }
    //         }
    //     }
    //     .kv-right-mobile,
    //     .kv-right,
    //     .kv-left{
    //         position: absolute;
    //         z-index: 1;
    //         top: 50%;
    //         transform: translateY(-50%);
    //         height: 100%;
    //         width: calc( ( 100% - 600px - 100px ) / 2);
    //         overflow: hidden;
    //         transition: all .3s;
    //         @media (max-width: v.$desktop-size){
    //             width: calc( ( 100% - 70% + 100px ) / 2);
    //         }
    //         img{
    //             position: absolute;
    //             top: 0;
    //             height: 90%;
    //             @media (max-width: v.$desktop-size){
    //                 height: 70%;
    //             }
    //         }
    //     }
    //     .kv-right{
    //         right: 0;
    //         img{
    //             left: 0;
    //         }
    //         @media (max-width: v.$tablet-size){
    //             opacity: 0;
    //         }
    //     }
    //     .kv-left{
    //         left: 0;
    //         img{
    //             right: 0;
    //         }
    //         @media (max-width: v.$tablet-size){
    //             opacity: 0;
    //         }
    //     }
    //     .kv-right-mobile{
    //         right: 0;
    //         opacity: 0;
    //         img{
    //             left: 0;
    //             top: 50%;
    //             transform: translateY(-50%);
    //         }
    //         @media (max-width: v.$tablet-size){
    //             opacity: 1;
    //             width: 40%;
    //         }
    //         @media (max-width: v.$mobile-size-l){
    //             width: 30%;
    //         }
    //     }
    // }
    .casino-top{
        position: relative;
        background: linear-gradient(to bottom, #fff 20%,#f6f6f6);
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #f6f6f6);
        }
        .main-wrap{
            position: relative;
            z-index: 3;
            padding: 40px 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 75%;
            max-width: 1100px;
            @media (max-width: v.$desktop-size){
                width: 70%;
            }
            @media (max-width:v.$tablet-size){
                width: 50%;
            }
            @media (max-width: v.$mobile-size-l){
                width: 85%;
                margin: auto;
            }
        }
        .small-text-tag{
            margin-bottom: 20px;
            padding: 10px 20px;
            border-radius: 50px;
            background: #f5f6ff;
            font-size: 15px;
            font-weight: 500;
            transition: font-size .3s;
            @media (max-width: v.$tablet-size){
                font-size: 12px;
            }
        }
        h1{
            margin-bottom: 30px;
            font-size: 64px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            max-width: 100%;
            transition: font-size .3s;
            text-transform: capitalize;
            strong{
                display: block;
            }
            @media (max-width: v.$tablet-size){
                font-size: 32px;
                max-width: unset;
                line-height: 1.2;
                strong{
                    font-size: 44px;
                    margin-bottom: 8px;
                }
            } 
            @media (max-width: v.$mobile-size-l){
                font-size: 26px;
                margin-bottom: 10px;
                strong{
                    font-size: 34px;
                }
            }
        }
        p{
            margin-bottom: 30px;
            line-height: 1.5;
            color: v.$color-text-light;
            font-weight: 400;
            text-align: center;
            max-width: 600px;
            @media (max-width: v.$tablet-size){
                text-align: left;
                display: none;
            }
            @media (max-width: v.$mobile-size-l){
                font-size: 14px;
                
            }
        }
        p.desc-mobile{
            display: none;
            @media (max-width: v.$tablet-size){
                display: block;
            }
        }
        .button-group{
            display: flex;
            align-items: center;
            gap: 20px;
            >*{
                min-width: 130px;
            }
            @media (max-width: v.$tablet-size){
                width: 50%;
                >*{
                    flex: 1;
                    min-width: unset;
                }
            }
        }
        
        
        
        .kv-cp {
            width: 95%;
            max-width: 1280px;
            position: relative;
            margin:  -3vw auto 30px;
            z-index: 3;
            
            img {
                display: block;
                width: 100%;
            }
            @media (max-width: v.$mobile-size-l){
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
                img {
                    width: 150%;
                    margin-left: -25%;
                }
            }
        }
    }
    .casino-demo{
        padding: 80px 0 60px;
        background: #f6f6f6;
        @media (max-width: v.$tablet-size){
            padding: 20px 0;
        }
        *.mobile-show{
            margin: 0 auto;
            width: 85%;
            text-align: center;
            display: none;
            @media (max-width: v.$desktop-size){
                display: block;
            }
        }
        .main-wrap{
            display: flex;
            justify-content: space-between;
            @media (max-width: v.$tablet-size){
                flex-direction: column;
                align-items: center;
            }
            .left{
                width: 32%;
                @media (max-width: v.$desktop-size){
                    width: 36%;
                }
                @media (max-width: v.$tablet-size){
                    width: 80%;
                    margin-bottom: 40px;
                }
                @media (max-width: v.$mobile-size-l){
                    width: 95%;
                }
               
            }
            .right{
                width: 60%;
                @media (max-width: v.$desktop-size){
                    width: 57%;
                }
                @media (max-width: v.$tablet-size){
                    width: 100%;
                }
                @media (max-width: v.$mobile-size-l){
                }
            }
        }
        .home-demo-title{
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            transition: font-size .3s;
            text-transform: capitalize;
            @media (max-width: v.$desktop-size){
                font-size: 36px;
                &:not(.mobile-show){
                    display: none;
                }
            }
            @media (max-width: v.$tablet-size){
                line-height: 1.2;
            }
        }
        .home-demo-desc{
            margin-bottom: 30px;
            color: v.$color-text-light;
            line-height: 1.6;
            font-weight: 400;
            font-size: 18px;
            transition: font-size .3s;
            @media (max-width: v.$desktop-size){
                font-size: 16px;
                &:not(.mobile-show){
                    display: none;
                }
            }
        }
        .mobile-frame{
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            >img{
                position: relative;
                z-index: 2;
                display: block;
                width: 100%;
            }
            .video-bg{
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 96%;
                height: 96%;
                border-radius: 50px;
                background: #fff;
                >video{
                    position: absolute;
                    top: 51%;
                    left: 50%;
                    width: 94%;
                    transform: translate(-50%,-50%);
                }
            }
            
        }
        .home-demo-list{
            z-index: 2;
            @media (max-width: v.$tablet-size){
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .demo-item{
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;
                box-shadow: 0 0 20px 0 #d2d3de;
                border-radius: 20px;
                background: #fff;
                padding: 20px;
                width: 90%;
                @media (max-width: v.$desktop-size){
                    width: 100%;
                }
                @media (max-width: v.$tablet-size){
                    width: calc(50% - 10px);
                    margin-bottom: 20px;
                }
                @media (max-width: v.$mobile-size-l){
                    width: 100%;
                }
                .main-content{
                    >h4{
                        margin-bottom: 5px;
                        font-weight: 500;
                        line-height: 1.4;
                        font-size: 18px;
                        @media (max-width: v.$desktop-size){
                            font-size: 16px;
                        }
                    }
                    >p{
                        font-size: 16px;
                        color: v.$color-text-light;
                        line-height: 1.6;
                        @media (max-width: v.$desktop-size){
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .casino-intro{
        position: relative;
        display: flex;
        background: linear-gradient(to bottom, #fff,#eee);
        @media (max-width:v.$tablet-size){
            display: block;
        }
        >*{
            flex: 1;
        }
        .left{
            padding: 10% 8%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            @media (max-width:v.$desktop-size){
                padding: 8% 5%;
            }
            @media (max-width:v.$tablet-size){
                width: 80%;
                margin: 0 auto;
                padding: 8% 0;
            }
          
            .home-intro-title{
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                line-height: 1.2;
                text-transform: capitalize;
                @media (max-width:v.$tablet-size){
                    font-size: 36px;
                }
            }
            .home-intro-desc{
                margin-bottom: 40px;
                line-height: 1.6;
                @media (max-width:v.$tablet-size){
                    font-size: 14px;
                }
            }
            >img{
                display: block;
                width: 100%;
            }
        }
        .right{
            .casino-bg{
                position: relative;
                width: 100%;
                height: 100%;
                img{
                    position: absolute;
                    z-index: 1;
                    object-fit: cover;
                    width:100%;
                    height:100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @media (max-width:v.$tablet-size){
                        position: static;
                        transform: unset;

                    }
                }
            }
        }
    }
    .casino-temp{
        padding: 80px 0;
        text-align: center;
        @media (max-width: v.$tablet-size){
            padding: 40px 0;
        }
        @media (max-width: v.$mobile-size-l){
            padding: 20px 0;
        }

        .casino-temp-title{
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            transition: font-size .3s;
            text-transform: capitalize;
            @media (max-width: v.$desktop-size){
                font-size: 36px;
            }
            @media (max-width: v.$tablet-size){
                line-height: 1.2;
            }
        }
        .casino-temp-desc{
            margin-bottom: 40px;
            color: v.$color-text-light;
            line-height: 1.6;
            font-weight: 400;
            font-size: 18px;
            transition: font-size .3s;
            @media (max-width: v.$desktop-size){
                font-size: 16px;
                margin-bottom: 30px;
            }
        }
        .template-list{
            display: flex;
            justify-content: space-between;
            @media (max-width: v.$tablet-size){
                flex-direction: column;
            }
            >div{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                >img{
                    display: block;
                    width: 80%;
                    box-shadow: 0 0 20px 0 #d2d3de;
                    border-radius: 10px;
                    @media (max-width: v.$tablet-size){
                        margin-bottom: 20px;
                    }
                    @media (max-width: v.$mobile-size-l){
                        width: 95%;
                    }
                }
            }
        }

    }
}