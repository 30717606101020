@use '../common/_variables' as v;

.page-teamtool{
    .main-wrap{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .team-tool-navigation{
        padding-top: 40px;
    }
    .user-info{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: v.$color-text-lighter;
        font-size: 14px;
        >img{
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }
        .logout-text{
            cursor: pointer;
            text-decoration: underline;
        }


    }
}
