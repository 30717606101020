@use '../common/_variables' as v;
.page-news{
    a[href^="https://elfsight.com/linkedin-feed-widget"] {
        pointer-events: none !important;
    }
    .news-top{
        position: relative;
        .main-wrap{
            position: relative;
            z-index: 3;
            padding: 40px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 600px;
            @media (max-width: v.$desktop-size){
                width: 70%;
            }
            @media (max-width:v.$tablet-size){
                align-items: flex-start;
                margin: 0 auto;
                width: 80%;
                padding: 20px 0;
            }
            @media (max-width: v.$mobile-size-l){
                width: 90%;
                padding: 10px 0;
            }
        }
        .small-text-tag{
            margin-bottom: 20px;
            padding: 10px 20px;
            border-radius: 50px;
            background: #f5f6ff;
            font-size: 15px;
            font-weight: 500;
            transition: font-size .3s;
            @media (max-width: v.$tablet-size){
                font-size: 12px;
            }
        }
        h1{
            margin-bottom: 30px;
            font-size: 64px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            max-width: 100%;
            transition: font-size .3s;
            text-transform: capitalize;
            strong{
                display: block;
                text-transform:none;
            }
            @media (max-width: v.$tablet-size){
                font-size: 32px;
                max-width: unset;
                text-align: left;
                line-height: 1.2;
                strong{
                    font-size: 44px;
                    margin-bottom: 8px;
                }
            } 
            @media (max-width: v.$mobile-size-l){
                font-size: 26px;
                margin-bottom: 10px;
                strong{
                    font-size: 34px;
                }
            }
        }
        p{
            margin-bottom: 30px;
            line-height: 1.5;
            color: v.$color-text-light;
            font-weight: 400;
            text-align: center;
            max-width: 600px;
            @media (max-width: v.$tablet-size){
                text-align: left;
                display: none;
            }
            @media (max-width: v.$mobile-size-l){
                font-size: 14px;
                
            }
        }
        p.desc-mobile{
            display: none;
            @media (max-width: v.$tablet-size){
                display: block;
            }
        }
        
        
    }
    .news-cont {
        width: 90%;
        max-width: 1024px;
        margin: auto;
        position: relative;
        box-shadow: 0 0 20px 0 #d2d3de;
        border-radius: 20px;
        background: #fff;
        z-index: 10;
        padding: 20px 20px 0;
        overflow: hidden;
        > div {
            margin: 20px auto -50px;
            .Layout__LayoutContainer-sc-kw8iuy-1 {
                margin-bottom: 50px;
            }
            @media (max-width: v.$mobile-size-l){
                margin: 0px auto -50px;
            }
        }
        // .juicer-feed {
        //     h1.referral {
        //         display: none;
        //     }
        //     .j-stacker-wrapper {
        //         ul {
        //             li {
        //                 &.feed-item {
        //                     border-width: 0 0 1px 0;
        //                 }
        //                 > * {
        //                     font-family: Biennale;
        //                 }
        //                 .j-poster h3 {
        //                     opacity: 0;
        //                 }
        //                 &:last-of-type.feed-item {
        //                     border-width: 0 0 0 0;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}