@use '../common/_variables' as v;

.k-footer{
    padding: 30px 0;
    background: #fff;
    @media (max-width: v.$tablet-size){
        padding-top: 20px !important;
        padding-bottom: 90px !important;
    }
    .copyright{
        text-align: center;
        color: v.$color-text;
    }
}