@use '../common/_variables' as v;

.k-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #cecece;
    color: v.$color-text;
    font-size: 15px;
    user-select: none;
    transition: opacity .5s;
    cursor: pointer;
    i{
        font-size: 20px;
    }
    @media (min-width: v.$tablet-size){
        &:hover{
            opacity: .7;
        }
    }
    &.btn-block{
        width: 100%;
    }
    &.btn-m{
        height: 35px;
        padding: 0 14px;
    }
    &.btn-l{
        height: 45px;
        padding: 0 14px;
    }
    &.btn-s{
        height: 28px;
        padding: 0 10px;
        font-size: 14px;
    }
    &.btn-primary{
        background: v.$color-primary;
        border-color: v.$color-primary;
        color: #fff;
    }
    &.btn-white{
        background: #fff;
        border-color: #fff;
        color: v.$color-text;
        &:hover{
            opacity: 1;
            background-color: #eee;
            border-color: #eee;
        }
    }
    &.btn-text{
        padding: 0;
        border: 0;
        border-radius: 0;
    }
    &.btn-text-primary{
        color: v.$color-primary;
    }
    &.btn-outline-white{
        border-color: #fff;
        color: #fff;
        background: rgba(0,0,0,.01);
    }
    &.btn-outline-primary{
        border-color: v.$color-primary;
        color: v.$color-primary;
        background: transparent;
    }
    &.btn-disabled-color{
        opacity: .5;
        cursor: not-allowed;
    }

}
.k-btn-toggle-group{
    display: flex;
    align-items: center;
    justify-content: center;
    .k-btn-toggle-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 10px;
        background: #f9f9f9;
        margin: 0 10px;
        .k-btn-toggle{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            height: 40px;
            border-radius: 6px;
            text-align: center;
            min-width: 70px;
            font-size: 14px;
            line-height: 1.5;
            cursor: pointer;
            &.active{
                box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
                background: #fff;
                font-weight: bold;
                cursor: default;
            }
        }
        .k-btn-sepline{
            width: 1px;
            height: 25px;
            background: rgba(0,0,0,.1);
        }
    }
}
.k-btn-tags{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    .k-btn-tag{
        border: 1px solid #cecece;
        padding: 5px 15px;
        border-radius: 50px;
        font-size: 14px;
        cursor: pointer;
        &.active{
            background: v.$color-primary;
            border-color: v.$color-primary;
            color: #fff;
        }
    }
}