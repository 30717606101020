@use '../common/_variables' as v;

html,body{
    color: v.$color-text;
    -webkit-font-smoothing: antialiased;
    font-family: 'Biennale';

}
.main-wrap{
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
    max-width: v.$desktop-size;
}
//版型選擇頁面表單會擋到footer
.app.Template{
    @media (max-width: v.$tablet-size){
        .k-footer{
            padding-top: 20px;
            padding-bottom: 90px;
        }
    }
}
