@use '../common/_variables' as v;
.page-viewer {
    @media (max-width:v.$desktop-size){
        padding-top: 10px;
    }
    @media (max-width: v.$tablet-size){
        padding-top: 20px;
    }
    >.k-btn-toggle-group{
        display: none;
        @media (max-width: v.$mobile-size-l){ 
            display: flex;
        }
    }
    .viewer-top{
        position: relative;
        background: linear-gradient(to bottom, #fff 20%,#f6f6f6);
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #f6f6f6);
        }
        .main-wrap{
            position: relative;
            z-index: 3;
            padding: 40px 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 75%;
            max-width: 1100px;
            @media (max-width: v.$desktop-size){
                width: 70%;
            }
            @media (max-width:v.$tablet-size){
                width: 50%;
            }
            @media (max-width: v.$mobile-size-l){
                width: 85%;
                margin: auto;
            }
        }
        .small-text-tag{
            margin-bottom: 20px;
            padding: 10px 20px;
            border-radius: 50px;
            background: #f5f6ff;
            font-size: 15px;
            font-weight: 500;
            transition: font-size .3s;
            @media (max-width: v.$tablet-size){
                font-size: 12px;
            }
        }
        h1{
            margin-bottom: 30px;
            font-size: 64px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            max-width: 100%;
            transition: font-size .3s;
            text-transform: capitalize;
            strong{
                display: block;
            }
            @media (max-width: v.$tablet-size){
                font-size: 32px;
                max-width: unset;
                line-height: 1.2;
                strong{
                    font-size: 44px;
                    margin-bottom: 8px;
                }
            } 
            @media (max-width: v.$mobile-size-l){
                font-size: 26px;
                margin-bottom: 10px;
                strong{
                    font-size: 34px;
                }
            }
        }
        p{
            margin-bottom: 30px;
            line-height: 1.5;
            color: v.$color-text-light;
            font-weight: 400;
            text-align: center;
            max-width: 600px;
            @media (max-width: v.$tablet-size){
                text-align: left;
                display: none;
            }
            @media (max-width: v.$mobile-size-l){
                font-size: 14px;
                
            }
        }
        p.desc-mobile{
            display: none;
            @media (max-width: v.$tablet-size){
                display: block;
            }
        }
        .button-group{
            display: flex;
            align-items: center;
            gap: 20px;
            >*{
                min-width: 130px;
            }
            @media (max-width: v.$tablet-size){
                width: 50%;
                >*{
                    flex: 1;
                    min-width: unset;
                }
            }
        }
        .kv-right,
        .kv-left{
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            width: 28%;
            overflow: hidden;
            transition: all .3s;
            
            img{
                position: relative;
                
            }
            @media (max-width: v.$mobile-size-l){
                
            }
        }
        
        .kv-left {
            top: 60%;
            img {
                width: 180%;
                right: 0;
            }
            @media (max-width: v.$tablet-size){
                top: 50%;
                width: 40%;
            }
            
        }
        .kv-right{
            right: 0;
            img{
                left: 10%;
                top: 7%;
                width: 150%;
            }
            @media (max-width: v.$tablet-size){
                top: 42%;
                width: 40%;
            }
        }
        
        
        .kv-viewer {
            width: 95%;
            max-width: 1500px;
            position: relative;
            margin:  -2vw auto 0;
            z-index: 3;
            
            img {
                display: block;
                width: 100%;
            }
            @media (max-width: v.$mobile-size-l){
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
                img {
                    width: 150%;
                    margin-left: -25%;
                }
            }
        }
    }
    .viewer-demo{
        padding: 80px 0 60px;
        background: #f6f6f6;
        position: relative;
        .viewer-demo-cont {
            position: relative;
            z-index: 1;
            .main-wrap {
                @media (max-width: v.$mobile-size-l){
                    padding: 0;
                }
            }
        }
        .video-bg {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            overflow: hidden;
            &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(#f7f7f7, 1) 20%, rgba(#efefef, 0.9) 60%,rgba(#efefef, 0.5) 90%);
                top: 0;
                left: 0;
                display: flex;
            }
            video {
                width: 100%;
                height: auto;
            }
        }
        @media (max-width: v.$tablet-size){
            padding: 20px 0;
        }
        @media (max-width: v.$mobile-size-l){
            padding: 50px 0 20px;
        }
        *.mobile-show{
            margin: 0 auto;
            width: 85%;
            text-align: center;
            display: none;
            @media (max-width: v.$desktop-size){
                display: block;
            }
        }
        .main-wrap{
            display: flex;
            justify-content: space-between;
            @media (max-width: v.$tablet-size){
                flex-direction: column;
                align-items: center;
            }
            .left{
                width: 85%;
                margin: auto;
                @media (max-width: v.$mobile-size-l){
                    width: 100%;
                }
               
            }
        }
        .home-demo-title{
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            transition: font-size .3s;
            text-transform: capitalize;
            @media (max-width: v.$desktop-size){
                font-size: 36px;
                &:not(.mobile-show){
                    display: none;
                }
            }
            @media (max-width: v.$tablet-size){
                line-height: 1.2;
            }
            @media (max-width: v.$mobile-size-l){
                font-size: 21px;
            }
        }
        .home-demo-desc{
            margin-bottom: 30px;
            color: v.$color-text-light;
            line-height: 1.6;
            font-weight: 400;
            font-size: 18px;
            transition: font-size .3s;
            @media (max-width: v.$desktop-size){
                font-size: 16px;
                &:not(.mobile-show){
                    display: none;
                }
            }
        }
        
        .home-demo-list{
            z-index: 2;
            @media (max-width: v.$tablet-size){
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .demo-item{
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                width: 100%;
                position: relative;
                min-height: 300px;
                > img {
                    width: 55%;
                    position: absolute;
                    z-index: 1;
                    border-radius: 5px;
                }
                @media (max-width: v.$mobile-size-l){
                    width: 100%;
                    flex-wrap: wrap;
                    > img {
                        position: relative;
                        width: 85%;
                        margin: auto;
                    }
                }
                &:nth-of-type(2) ,
                &:nth-of-type(4) {
                    > img {
                        right: 0;
                    }
                    .main-content {
                        padding: 20px 58% 20px 30px;
                        @media (max-width: v.$mobile-size-l) {
                            padding: 30px 15px 20px;
                        }
                    }
                        
                }
                .main-content{
                    box-shadow: 0 0 20px 0 #d2d3de;
                    border-radius: 20px;
                    background: rgba(#fff, 0.8);
                    backdrop-filter: blur(5px);
                    padding: 20px 30px 20px 58%;
                    height: 200px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    
                    >h4{
                        margin-bottom: 8px;
                        font-weight: 500;
                        line-height: 1.4;
                        font-size: 21px;
                        flex: 0 0 100%;
                    }
                    >p{
                        font-size: 16px;
                        color: v.$color-text-light;
                        line-height: 1.6;
                    }
                    @media (max-width: v.$mobile-size-l){
                        padding: 30px 15px 20px;
                        margin-top: -20px;
                        height: auto;
                        border-radius: 10px;
                        width: 100%;
                        min-height: 120px;
                        > h4 {
                            font-size: 16px;
                        }
                        >p {
                            font-size: 14px;
                            line-height: 1.4;
                        }
                    }
                }
            }
            
        }
    }
    
    
}