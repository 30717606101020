@use '../common/_variables' as v;
.page-contactus{
    padding: 50px 0;
    .main-contactus{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        min-height: calc( 90vh - 100px );
        width: calc(100% - 40px);
        max-width: v.$desktop-size;
        background: #fff;
        text-align: center;
        @media (max-width: v.$tablet-size){
            min-height: calc( 90vh - 40px );
        }
        .page-title{
            margin-bottom: 50px;
            font-weight: bold;
            font-size: 36px;
            &.sub {
                font-size: 21px;
                margin: 30px auto 10px;
            }
        }
        .contact-notice {
            font-size: 16px;
        }
        .business-qr-wrap{
            display: flex;
            gap: 20px;
            @media (max-width: v.$desktop-size){
                display: block;
            }
            >*{
                flex:1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px 10px;
                border: 2px solid #f5f6ff;
                border-radius: 20px;
                transition: border-color .3s ease;
                @media (max-width: v.$desktop-size){
                    margin-bottom: 20px;
                }
                &:hover{
                    border-color: #000;
                }
            }
            &.sub {
                width: 100%;
                margin-top: 20px;
                flex-wrap: wrap;
                justify-content: center;
                >div {
                    flex: 0 0 23%;
                }
                .business-name {
                    margin-bottom: 8px;
                }
                .bd-lang {
                    display: flex;
                    margin-bottom: 10px;
                    gap: 5px;
                    > img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        box-shadow: 0 0 0 1px #ccc;
                    }
                }
            }
        }
    
        .business-qr{
            margin-bottom: 10px;
            img{
                display: block;
                width: 140px;
            }
        }
        .business-avatar{
            margin-bottom: 5px;
            img{
                width: 100px;
                border-radius: 50%;
                border: 4px solid #ddd;
            }
        }
        .business-title{
            margin-bottom: 15px;
            font-size: 15px;
            color: v.$color-text-lighter;
        }
        .business-name{
            margin-bottom: 5px;
            font-size: 28px;
        }
        .contact-desc{
            width: 80%;
            line-height: 1.4;
            margin-bottom: 15px;
            color: v.$color-text-lighter;
            @media (max-width: v.$tablet-size){
                width: 100%;
            }
        }
        .sns-group{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            >div a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                padding: 10px;
                border: 1px solid #000;
                min-width: 150px;
                text-decoration: none;
                color: v.$color-text;
                cursor: pointer;
                border-radius: 6px;
                transition: .3s ease;
                >img{
                    display: block;
                    height: 20px;
                }
                &:hover {
                    box-shadow: 0 0 0 1px #000;
                }
            }
            @media (max-width: v.$tablet-size){
                flex-direction: column;
                width: 100%;
                >div{
                    min-width: 60%;
                }
            }
        }
        .member-list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
            .member-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(50% - 5px);
                gap: 10px;
                text-align: left;
                padding: 20px 10px 20px 20px;
                border: 2px solid #f5f6ff;
                border-radius: 20px;
                transition: border-color .3s ease;
                @media (max-width: v.$tablet-size){
                    width: 100%;
                }
                &:hover{
                    border-color: #000;
                }
                .member-info{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
                .member-name{
                    font-size: 18px;
                }
                .member-title{
                    font-size: 12px;
                    color: v.$color-text-lighter;
                }
                .member-link{
                    a{
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        gap: 4px;
                        color: v.$color-text;
                        font-size: 14px;
                        span{
                            color: #c8cadd;
                        }

                    }
                }
            }
        }
    }
}