@use '../common/_variables' as v;
.page-login{
    margin: 0 auto;
    max-width: 400px;
    height: calc(100vh - 60px - 126px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .login-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 10px;
        margin-bottom: 20px;
        background: v.$color-primary;
        img{
            display: block;
            width: 70%;
        }
    }
    .login-title{
        font-weight: bold;
        font-size: 32px;
    }
}
